<template>
  <div class="welcoming flex-center gap30">
    <img class="image-logo" src="../../assets/images/empty.png" />
    <div class="fs18" style="font-weight: 400">
      暂无应用
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardsStatistics',
  setup() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.welcoming {
  background-color: #fff;
  height: 100%;
  border-radius: 12px;
  flex-direction: column;

  .image-logo {
    width: 400px;
  }
}
</style>
